import * as React from 'react';
import BabylonScene from './Scene'; 
import {SceneEventArgs} from './Scene';


import * as BABYLON from 'babylonjs';
import { AssetsManager, CubeTexture, DefaultLoadingScreen, Vector3, Mesh, BinaryFileAssetTask, Observable } from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders'; 


import NSMSceneBehavior from './NSMSceneBehavior';
import {MainGallery} from './Components/MainGallery';
import {ModalCallbacks, ImageCarouselAsset} from './PageWithScene';
import {CustomLoadingScreen} from './Components/LoadingScreen';
import { refractionPixelShader } from 'babylonjs/Shaders/refraction.fragment';


import { Vector2WithInfo } from 'babylonjs-gui';
 
let nsmSceneBehaviors : NSMSceneBehavior[] = []; 

let ambientMusicTask : BinaryFileAssetTask;
let ambientMusic : BABYLON.Sound;
let ambientIsPlaying = false;

const NSMGalleryScene = (e : SceneEventArgs, modalCallbacks : ModalCallbacks) => {
    const { canvas, scene, engine } = e;

    //BABYLON.Engine.audioEngine.useCustomUnlockedButton = false;

  
    //scene.debugLayer.show();

    
    
    let loadingScreen = new CustomLoadingScreen();
    engine.loadingScreen = loadingScreen;
    engine.displayLoadingUI();
    
    scene.clearColor = new BABYLON.Color4(0,0,0,1);
   
    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.FreeCamera("MainCamera", new BABYLON.Vector3(-16, 1.11, 0), scene);// new BABYLON.FreeCamera("MainCamera", new BABYLON.Vector3(0, 2, 9), scene);   
    camera.minZ = 0.1; 
    camera.fov = 65 * Math.PI / 180;    
    camera.setTarget(BABYLON.Vector3.Zero()); // This targets the camera to scene origin        
    //camera.attachControl(canvas, true);// This attaches the camera to the canvas
    camera.speed = 0.25;
    camera.rotation.x = -2 * Math.PI / 180;
    camera.ellipsoid = new BABYLON.Vector3(0.1, 0.7, 0.1);

    camera.angularSensibility *= -2;
    //camera.inputs.removeByType("FreeCameraTouchInput");
    //camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
    //camera.inputs.add ("FreeCameraKeyboardMoveInput");

    camera.attachControl(canvas, true);

    //Scene HDR    
    
    var hdrTexture = new BABYLON.HDRCubeTexture("./assets/textures/studio024.hdr", scene, 512, false, true, true);     
    
    var hdrRotation = 0; // in degrees
    hdrTexture.setReflectionTextureMatrix(
        BABYLON.Matrix.RotationY(
            BABYLON.Tools.ToRadians(hdrRotation)
        )
    );

    scene.environmentTexture = hdrTexture;
    scene.environmentIntensity = 3;

    //scene.createDefaultSkybox(hdrTexture, true, 2000);    
    //scene.createDefaultEnvironment({
    //    environmentTexture: hdrTexture
    //})
    
   
    //Render pipeline 
    
    var pipeline = new BABYLON.DefaultRenderingPipeline(
        "defaultPipeline", // The name of the pipeline
        true, // Do you want the pipeline to use HDR texture?
        scene, // The scene instance
        [camera] // The list of cameras to be attached to
    );
    //pipeline.samples = 4;
    pipeline.fxaaEnabled = true;    
    

    //Setup asset manager
    let assetsManager : BABYLON.AssetsManager = new BABYLON.AssetsManager(scene);
    //assetsManager.useDefaultLoadingScreen = false;

    //Setup action manager
    scene.actionManager = new BABYLON.ActionManager(scene);

    /********************************************************
    * 
    * SCENE SETUP
    * 
    *******************************************************/

     



    //Main gallery and walk mechanics
    let mainGallery : MainGallery = new MainGallery();
    nsmSceneBehaviors.push(mainGallery);

         

    
    


    /********************************************************
    * 
    * LifeCycle Startup
    * 
    *******************************************************/   

    nsmSceneBehaviors.forEach(function (sceneBehavior) {
        sceneBehavior.Start(e, camera);
        sceneBehavior.LoadAssets(assetsManager);
    }); 
   


    /********************************************************
    * 
    * Asset Loading and Run Loop
    * 
    *******************************************************/      
        
    assetsManager.onFinish = function(tasks) {

        nsmSceneBehaviors.forEach(function (sceneBehavior) {
            sceneBehavior.OnAssetLoadComplete();
        }); 

        engine.runRenderLoop(() => {
            if (scene) {
                nsmSceneBehaviors.forEach(function (sceneBehavior) {
                    sceneBehavior.Update();
                    update();
                }); 

                
                scene.render();
            }
        });
    };
    assetsManager.load();
//

    let update = () => {
        checkAmbientMusic();
    }


    let checkAmbientMusic = () => {
        if(camera.position.z < -6){
            playAmbient();
        }

    }

    let playAmbient = () => {       
       return;
        if(ambientIsPlaying || ambientMusic.isPlaying) return;
        console.log("Play Ambient B");
        ambientIsPlaying = true;
        ambientMusic.play();
        //
    }



    let audioEngineUnlocked = false;
    let onPointerObservable = (pointerInfo : BABYLON.PointerInfo) =>{
        
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
                //console.log("POINTER DOWN");
                unlockAudio();
                break;
            case BABYLON.PointerEventTypes.POINTERUP:
                //console.log("POINTER UP");
                break;
            case BABYLON.PointerEventTypes.POINTERMOVE:
                //console.log("POINTER MOVE");
              
                //unlockAudio();
               
                

                break;
            case BABYLON.PointerEventTypes.POINTERWHEEL:
                //console.log("POINTER WHEEL");
                break;
            case BABYLON.PointerEventTypes.POINTERPICK:
                //console.log("POINTER PICK");
                //unlockAudio();
                break;
            case BABYLON.PointerEventTypes.POINTERTAP:
                //console.log("POINTER TAP");
                //unlockAudio();
                

                break;
            case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
                //console.log("POINTER DOUBLE-TAP");

                
                break;
        }
    }
    scene.onPointerObservable.add(onPointerObservable);

    let unlockAudio = () => {
        //if(audioEngineUnlocked) return;
        //audioEngineUnlocked = true;
        //BABYLON.Engine.audioEngine.unlock();
        //console.log("UNLOCK");
    }

           
}




export default NSMGalleryScene;