import * as React from 'react';
import * as BABYLON from 'babylonjs';
import BabylonScene from './Scene'; // import the component above linking to file we just created.
import {SceneEventArgs} from './Scene';
import './NSMGallery';
import NSMGalleryScene from './NSMGallery';



interface Props {
    titleAndTextModalCallback: (title: string, subTitle: string, text: string) => void ;
    titleTextAndImageCallback: (title: string, subTitle: string, text: string, imagePath: string) => void;
    titleTextAndVideoCallback: (title: string, subTitle: string, text: string, videoPath: string) => void;
    titleTextAndImageCarouselCallback: (title: string, subTitle: string, text: string, imageCarouselAssets: ImageCarouselAsset[]) => void;
}

interface State {
  
};


export type ImageCarouselAsset = {
    title : string,
    text : string,
    imagePath : string
}


export type ModalCallbacks = {

    titleAndTextModalCallback: (title: string, subTitle: string, text: string) => void | undefined ;
    titleTextAndImageCallback: (title: string, subTitle: string, text: string, imagePath: string) => void | undefined;
    titleTextAndVideoCallback: (title: string, subTitle: string, text: string, videoPath: string) => void | undefined;
    titleTextAndImageCarouselCallback: (title: string, subTitle: string, text: string, imageCarouselAssets: ImageCarouselAsset[]) => void| undefined;

}


export default class PageWithScene extends React.Component<Props, State> {


    modalCallbacks : ModalCallbacks;

    
    onSceneMount = (e: SceneEventArgs) => {


        this.modalCallbacks = {
            titleAndTextModalCallback : this.props.titleAndTextModalCallback,
            titleTextAndImageCallback : this.props.titleTextAndImageCallback,
            titleTextAndVideoCallback : this.props.titleTextAndVideoCallback,
            titleTextAndImageCarouselCallback : this.props.titleTextAndImageCarouselCallback
        };

        NSMGalleryScene(e, this.modalCallbacks);
        //ScratchScene(e, this.props.modalCallback);
/*
        const { canvas, scene, engine } = e;

        // This creates and positions a free camera (non-mesh)
        var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);

        // This targets the camera to scene origin
        camera.setTarget(BABYLON.Vector3.Zero());

        // This attaches the camera to the canvas
        camera.attachControl(canvas, true);

        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), scene);

        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.7;

        // Our built-in 'sphere' shape. Params: name, subdivs, size, scene
        var sphere = BABYLON.Mesh.CreateSphere("sphere1", 16, 2, scene);

        // Move the sphere upward 1/2 its height
        sphere.position.y = 1;

        // Our built-in 'ground' shape. Params: name, width, depth, subdivs, scene
        var ground = BABYLON.Mesh.CreateGround("ground1", 6, 6, 2, scene);

        engine.runRenderLoop(() => {
            if (scene) {
                scene.render();
            }
        });
        */
    }

    voidCallback = () => {} 

    render() {               
        return (
            
                <BabylonScene onSceneMount={this.onSceneMount}/>
         
        )
    }
}

